import axios from "axios";

axios.defaults.headers.common["X-CSRF-TOKEN"] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {
    // ********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },



    // ********************************************************************| Dictionaries |***********************************************************
    // Diseases
    GetDiseases(pageNo, pageSize, Type) {
        return axios.get(`api/admin/Dictionaries/Diseases/Get?pageno=${pageNo}&pagesize=${pageSize}&Type=${Type}`);
    },

    GetDiseasesByType(Type) {
        return axios.get(`api/admin/Dictionaries/Diseases/GetByType?Type=${Type}`);
    },

    AddDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Diseases/Add`, bodyObject);
    },

    EditDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Diseases/Edit`, bodyObject);
    },

    DeleteDiseases(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Diseases/Delete`);
    },



    // AnalysisExaminations
    GetAnalysisExaminations(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/AnalysisExaminations/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetAnalysisExaminationsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/AnalysisExaminations/GetById?id=${id}`);
    },

    GetAllAnalysisExaminationsName() {
        return axios.get(`/Api/Admin/Dictionaries/AnalysisExaminations/GetAll`);
    },

    AddAnalysisExaminations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnalysisExaminations/Add`, bodyObject);
    },

    EditAnalysisExaminations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnalysisExaminations/Edit`, bodyObject);
    },

    DeleteAnalysisExaminations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AnalysisExaminations/Delete`);
    },








    // Animals
    GetAnimals(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Animals/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetAnimalsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Animals/GetById?id=${id}`);
    },

    GetAllAnimalsName() {
        return axios.get(`/Api/Admin/Dictionaries/Animals/GetAll`);
    },

    AddAnimals(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Animals/Add`, bodyObject);
    },

    EditAnimals(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Animals/Edit`, bodyObject);
    },

    DeleteAnimals(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Animals/Delete`);
    },




    // ChronicDiseases
    GetChronicDiseases(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/ChronicDiseases/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetChronicDiseasesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/ChronicDiseases/GetById?id=${id}`);
    },

    GetAllChronicDiseasesName() {
        return axios.get(`/Api/Admin/Dictionaries/ChronicDiseases/GetAll`);
    },

    AddChronicDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ChronicDiseases/Add`, bodyObject);
    },

    EditChronicDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ChronicDiseases/Edit`, bodyObject);
    },

    DeleteChronicDiseases(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/ChronicDiseases/Delete`);
    },





    // Complications
    GetComplications(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Complications/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetComplicationsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Complications/GetById?id=${id}`);
    },

    GetAllComplicationsName() {
        return axios.get(`/Api/Admin/Dictionaries/Complications/GetAll`);
    },

    AddComplications(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Complications/Add`, bodyObject);
    },

    EditComplications(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Complications/Edit`, bodyObject);
    },

    DeleteComplications(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Complications/Delete`);
    },


    // Countries
    GetCountries(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Countries/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllCountries() {
        return axios.get(`api/admin/Dictionaries/Countries/GetAll`);
    },

    AddCountries(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Countries/Add`, bodyObject);
    },

    EditCountries(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Countries/Edit`, bodyObject);
    },

    DeleteCountries(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Countries/Delete`);
    },


    // Branches
    GetBranches(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Branches/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllBranches() {
        return axios.get(`api/admin/Dictionaries/Branches/GetAll`);
    },

    AddBranches(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Branches/Add`, bodyObject);
    },

    EditBranches(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Branches/Edit`, bodyObject);
    },

    DeleteBranches(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Branches/Delete`);
    },



    // Municipalities
    GetMunicipalities(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetAllMunicipalities(id) {
        return axios.get(`api/admin/Dictionaries/Municipalities/GetAll?id=${id}`);
    },

    AddMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Add`, bodyObject);
    },

    EditMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Edit`, bodyObject);
    },

    DeleteMunicipalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Municipalities/Delete`);
    },



    // Locality
    GetLocality(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Locality/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetLocalityName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Locality/GetById?id=${id}`);
    },

    GetAllLocalityName() {
        return axios.get(`/Api/Admin/Dictionaries/Locality/GetAll`);
    },

    AddLocality(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Locality/Add`, bodyObject);
    },

    EditLocality(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Locality/Edit`, bodyObject);
    },

    DeleteLocality(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Locality/Delete`);
    },




    // Facilities
    GetFacilities(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Facilities/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetFacilitiesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Facilities/GetById?id=${id}`);
    },

    GetAllFacilitiesName() {
        return axios.get(`/Api/Admin/Dictionaries/Facilities/GetAll`);
    },

    AddFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Add`, bodyObject);
    },

    EditFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Edit`, bodyObject);
    },

    DeleteFacilities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Facilities/Delete`);
    },





   

    // Nationalities
    GetNationalities(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Nationalities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllNationalities() {
        return axios.get(`api/admin/Dictionaries/Nationalities/GetAll`);
    },

    AddNationalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Nationalities/Add`, bodyObject);
    },

    EditNationalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Nationalities/Edit`, bodyObject);
    },

    DeleteNationalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Nationalities/Delete`);
    },







    // InitialDiagnosis
    GetInitialDiagnosis(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/InitialDiagnosis/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetInitialDiagnosisName(id) {
        return axios.get(`/Api/Admin/Dictionaries/InitialDiagnosis/GetById?id=${id}`);
    },

    GetAllInitialDiagnosisName() {
        return axios.get(`/Api/Admin/Dictionaries/InitialDiagnosis/GetAll`);
    },

    AddInitialDiagnosis(bodyObject) {
        return axios.post(`api/admin/Dictionaries/InitialDiagnosis/Add`, bodyObject);
    },

    EditInitialDiagnosis(bodyObject) {
        return axios.post(`api/admin/Dictionaries/InitialDiagnosis/Edit`, bodyObject);
    },

    DeleteInitialDiagnosis(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/InitialDiagnosis/Delete`);
    },



    // Laboratories
    GetLaboratories(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Laboratories/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetLaboratoriesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Laboratories/GetById?id=${id}`);
    },

    GetAllLaboratoriesName() {
        return axios.get(`/Api/Admin/Dictionaries/Laboratories/GetAll`);
    },

    AddLaboratories(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Laboratories/Add`, bodyObject);
    },

    EditLaboratories(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Laboratories/Edit`, bodyObject);
    },

    DeleteLaboratories(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Laboratories/Delete`);
    },



    // LesionSites
    GetLesionSites(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/LesionSites/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetLesionSitesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/LesionSites/GetById?id=${id}`);
    },

    GetAllLesionSitesName() {
        return axios.get(`/Api/Admin/Dictionaries/LesionSites/GetAll`);
    },

    AddLesionSites(bodyObject) {
        return axios.post(`api/admin/Dictionaries/LesionSites/Add`, bodyObject);
    },

    EditLesionSites(bodyObject) {
        return axios.post(`api/admin/Dictionaries/LesionSites/Edit`, bodyObject);
    },

    DeleteLesionSites(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/LesionSites/Delete`);
    },







    // Occupations
    GetOccupations(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Occupations/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetOccupationsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Occupations/GetById?id=${id}`);
    },

    GetAllOccupationsName() {
        return axios.get(`/Api/Admin/Dictionaries/Occupations/GetAll`);
    },

    AddOccupations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Occupations/Add`, bodyObject);
    },

    EditOccupations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Occupations/Edit`, bodyObject);
    },

    DeleteOccupations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Occupations/Delete`);
    },





    // PatientsStatus
    GetPatientsStatus(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/PatientsStatus/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetPatientsStatusName(id) {
        return axios.get(`/Api/Admin/Dictionaries/PatientsStatus/GetById?id=${id}`);
    },

    GetAllPatientsStatusName() {
        return axios.get(`/Api/Admin/Dictionaries/PatientsStatus/GetAll`);
    },

    AddPatientsStatus(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PatientsStatus/Add`, bodyObject);
    },

    EditPatientsStatus(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PatientsStatus/Edit`, bodyObject);
    },

    DeletePatientsStatus(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PatientsStatus/Delete`);
    },



    // Procedures
    GetProcedures(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Procedures/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetProceduresName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Procedures/GetById?id=${id}`);
    },

    GetAllProceduresName() {
        return axios.get(`/Api/Admin/Dictionaries/Procedures/GetAll`);
    },

    AddProcedures(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Procedures/Add`, bodyObject);
    },

    EditProcedures(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Procedures/Edit`, bodyObject);
    },

    DeleteProcedures(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Procedures/Delete`);
    },



    // RapidTests
    GetRapidTests(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/RapidTests/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetRapidTestsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/RapidTests/GetById?id=${id}`);
    },

    GetAllRapidTestsName() {
        return axios.get(`/Api/Admin/Dictionaries/RapidTests/GetAll`);
    },

    AddRapidTests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/RapidTests/Add`, bodyObject);
    },

    EditRapidTests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/RapidTests/Edit`, bodyObject);
    },

    DeleteRapidTests(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/RapidTests/Delete`);
    },



    // Referrals
    GetReferrals(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Referrals/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetReferralsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Referrals/GetById?id=${id}`);
    },

    GetAllReferralsName() {
        return axios.get(`/Api/Admin/Dictionaries/Referrals/GetAll`);
    },

    AddReferrals(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Referrals/Add`, bodyObject);
    },

    EditReferrals(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Referrals/Edit`, bodyObject);
    },

    DeleteReferrals(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Referrals/Delete`);
    },




    // Relationship
    GetRelationship(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Relationship/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetRelationshipName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Relationship/GetById?id=${id}`);
    },

    GetAllRelationshipName() {
        return axios.get(`/Api/Admin/Dictionaries/Relationship/GetAll`);
    },

    AddRelationship(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Relationship/Add`, bodyObject);
    },

    EditRelationship(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Relationship/Edit`, bodyObject);
    },

    DeleteRelationship(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Relationship/Delete`);
    },




    // Results
    GetResults(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Results/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetResultsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Results/GetById?id=${id}`);
    },

    GetAllResultsName() {
        return axios.get(`/Api/Admin/Dictionaries/Results/GetAll`);
    },

    AddResults(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Results/Add`, bodyObject);
    },

    EditResults(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Results/Edit`, bodyObject);
    },

    DeleteResults(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Results/Delete`);
    },




    // Samples
    GetSamples(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Samples/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetSamplesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Samples/GetById?id=${id}`);
    },

    GetAllSamplesName() {
        return axios.get(`/Api/Admin/Dictionaries/Samples/GetAll`);
    },

    AddSamples(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Samples/Add`, bodyObject);
    },

    EditSamples(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Samples/Edit`, bodyObject);
    },

    DeleteSamples(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Samples/Delete`);
    },






    // SiteOfBleedings
    GetSiteOfBleedings(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/SiteOfBleedings/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetSiteOfBleedingsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/SiteOfBleedings/GetById?id=${id}`);
    },

    GetAllSiteOfBleedingsName() {
        return axios.get(`/Api/Admin/Dictionaries/SiteOfBleedings/GetAll`);
    },

    AddSiteOfBleedings(bodyObject) {
        return axios.post(`api/admin/Dictionaries/SiteOfBleedings/Add`, bodyObject);
    },

    EditSiteOfBleedings(bodyObject) {
        return axios.post(`api/admin/Dictionaries/SiteOfBleedings/Edit`, bodyObject);
    },

    DeleteSiteOfBleedings(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/SiteOfBleedings/Delete`);
    },






    // Tests
    GetTests(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Tests/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetTestsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Tests/GetById?id=${id}`);
    },

    GetAllTestsName() {
        return axios.get(`/Api/Admin/Dictionaries/Tests/GetAll`);
    },

    AddTests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Tests/Add`, bodyObject);
    },

    EditTests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Tests/Edit`, bodyObject);
    },

    DeleteTests(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Tests/Delete`);
    },





    // Treatments
    GetTreatments(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Treatments/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetTreatmentsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Treatments/GetById?id=${id}`);
    },

    GetAllTreatmentsName() {
        return axios.get(`/Api/Admin/Dictionaries/Treatments/GetAll`);
    },

    AddTreatments(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Treatments/Add`, bodyObject);
    },

    EditTreatments(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Treatments/Edit`, bodyObject);
    },

    DeleteTreatments(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Treatments/Delete`);
    },





    // AnimalContacts
    GetAnimalContacts(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/AnimalContacts/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetAnimalContactsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/AnimalContacts/GetById?id=${id}`);
    },

    GetAllAnimalContactsName() {
        return axios.get(`/Api/Admin/Dictionaries/AnimalContacts/GetAll`);
    },

    AddAnimalContacts(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnimalContacts/Add`, bodyObject);
    },

    EditAnimalContacts(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnimalContacts/Edit`, bodyObject);
    },

    DeleteAnimalContacts(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AnimalContacts/Delete`);
    },





    // Symptoms
    GetSymptoms(pageNo, pageSize, ParentId) {
        return axios.get(`api/admin/Dictionaries/Symptoms/Get?pageno=${pageNo}&pagesize=${pageSize}&ParentId=${ParentId}`);
    },

    GetSymptomsName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Symptoms/GetById?id=${id}`);
    },

    GetAllSymptomsName() {
        return axios.get(`/Api/Admin/Dictionaries/Symptoms/GetAll`);
    },

    AddSymptoms(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Symptoms/Add`, bodyObject);
    },

    EditSymptoms(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Symptoms/Edit`, bodyObject);
    },

    DeleteSymptoms(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Symptoms/Delete`);
    },



    


    // ********************************************************************| End Of Dications |***********************************************************





















    // ********************************************************************| Applications |***********************************************************

    AddApplications(bodyObject) {
        return axios.post("api/admin/Applications/Add", bodyObject);
    },

    EditApplications(bodyObject) {
        return axios.post("api/admin/Applications/Edit", bodyObject);
    },

    GetApplications(pageNo, pageSize, BranchesId, MunicipalitiesId, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &BranchesId=${BranchesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },

    DeleteApplications(Id) {
        return axios.post(`api/admin/Applications/${Id}/Delete`);
    },


    //Weekly
    GetApplicationsWeekly(pageNo, pageSize, BranchesId, MunicipalitiesId, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Weekly/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &BranchesId=${BranchesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },

    AddApplicationsWeekly(bodyObject) {
        return axios.post("api/admin/Applications/Weekly/Add", bodyObject);
    },

    EditApplicationsWeekly(bodyObject) {
        return axios.post("api/admin/Applications/Weekly/Edit", bodyObject);
    },

    DeleteApplicationsWeekly(Id) {
        return axios.post(`api/admin/Applications/${Id}/Weekly/Delete`);
    },





    //Request 
    AddRequest(bodyObject) {
        return axios.post("api/admin/Applications/Request/Add", bodyObject);
    },

    EditRequest(bodyObject) {
        return axios.post("api/admin/Applications/Request/Edit", bodyObject);
    },

    DeleteRequest(Id) {
        return axios.post(`api/admin/Applications/${Id}/Request/Delete`);
    },

    GetRequest(pageNo, pageSize, BranchesId, MunicipalitiesId, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Request/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &BranchesId=${BranchesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },

    AddRequestApplications(bodyObject) {
        return axios.post("api/admin/Applications/Request/AddApplications", bodyObject);
    },

    GetApplicationsRequest(Id) {
        return axios.get(`api/admin/Applications/Request/GetApplications?Id=${Id}`);
    },








    //ApplicationsLab
    AddApplicationsLab(bodyObject) {
        return axios.post("api/admin/Applications/Lab/Add", bodyObject);
    },

    GetApplicationsLab(Id) {
        return axios.get(`api/admin/Applications/Lab/Get?Id=${Id}`);
    },

    DeleteApplicationsLab(Id) {
        return axios.post(`api/admin/Applications/${Id}/Lab/Delete`);
    },

    GetApplicationsLabs(pageNo, pageSize, BranchesId, MunicipalitiesId, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Lab/GetLab?pageNo=${pageNo}&pagesize=${pageSize}
            &BranchesId=${BranchesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },

    AddApplicationsLabResutl(bodyObject) {
        return axios.post("api/admin/Applications/Lab/AddResult", bodyObject);
    },

    DeleteApplicationsLabRequest(Id) {
        return axios.post(`api/admin/Lab/${Id}/Delete`);
    },



















    
   






    //ApplicationsAttachments
    DeleteApplicationsAttachments(Id) {
        return axios.post(`api/admin/Applications/${Id}/Attachments/Delete`);
    },

    AddApplicationsAttachments(bodyObject) {
        return axios.post("api/admin/Applications/Attachments/Add", bodyObject);
    },



    //Travel
    DeleteApplicationsTravel(Id) {
        return axios.post(`api/admin/Applications/${Id}/Travel/Delete`);
    },

    AddApplicationsTravel(bodyObject) {
        return axios.post("api/admin/Applications/Travel/Add", bodyObject);
    },

    GetApplicationsTravel(Id) {
        return axios.get(`api/admin/Applications/Travel/Get?Id=${Id}`);
    },



    //DiseasesHistory
    AddApplicationsDiseasesHistory(bodyObject) {
        return axios.post("api/admin/Applications/DiseasesHistory/Add", bodyObject);
    },

    GetDiseasesHistory(Id) {
        return axios.get(`api/admin/Applications/DiseasesHistory/Get?Id=${Id}`);
    },

    DeleteDiseasesHistory(Id) {
        return axios.post(`api/admin/Applications/${Id}/DiseasesHistory/Delete`);
    },


    //BlodTransfusion
    AddApplicationsBlodTransfusion(bodyObject) {
        return axios.post("api/admin/Applications/BlodTransfusion/Add", bodyObject);
    },

    GetApplicationsBlodTransfusion(Id) {
        return axios.get(`api/admin/Applications/BlodTransfusion/Get?Id=${Id}`);
    },

    DeleteApplicationsBlodTransfusion(Id) {
        return axios.post(`api/admin/Applications/${Id}/BlodTransfusion/Delete`);
    },



    //Hospitalizations
    AddApplicationsHospitalizations(bodyObject) {
        return axios.post("api/admin/Applications/Hospitalizations/Add", bodyObject);
    },

    GetApplicationsHospitalizations(Id) {
        return axios.get(`api/admin/Applications/Hospitalizations/Get?Id=${Id}`);
    },

    DeleteApplicationsHospitalizations(Id) {
        return axios.post(`api/admin/Applications/${Id}/Hospitalizations/Delete`);
    },



    //ApplicationsInfected
    AddApplicationsInfected(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsInfected/Add", bodyObject);
    },

    GetApplicationsInfected(Id) {
        return axios.get(`api/admin/Applications/ApplicationsInfected/Get?Id=${Id}`);
    },

    DeleteApplicationsInfected(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsInfected/Delete`);
    },



    //ApplicationsContacts
    AddApplicationsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsContacts/Add", bodyObject);
    },

    GetApplicationsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsContacts/Delete`);
    },




    //ApplicationsSymptoms
    AddApplicationsSymptoms(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsSymptoms/Add", bodyObject);
    },

    GetApplicationsSymptoms(Id) {
        return axios.get(`api/admin/Applications/ApplicationsSymptoms/Get?Id=${Id}`);
    },

    DeleteApplicationsSymptoms(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsSymptoms/Delete`);
    },





    //ApplicationsOrgans
    AddApplicationsOrgans(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsOrgans/Add", bodyObject);
    },

    GetApplicationsOrgans(Id) {
        return axios.get(`api/admin/Applications/ApplicationsOrgans/Get?Id=${Id}`);
    },

    DeleteApplicationsOrgans(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsOrgans/Delete`);
    },




    //ApplicationsAnimalsContacts
    AddApplicationsAnimalsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsAnimalsContacts/Add", bodyObject);
    },

    GetApplicationsAnimalsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsAnimalsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsAnimalsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsAnimalsContacts/Delete`);
    },



















    // Lab
    AddLab(bodyObject) {
        return axios.post("api/admin/Applications/Lab/Add", bodyObject);
    },

    GetApplicationsLabRequest(pageNo, pageSize, CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Lab/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`)
    },

    AddLabResult(bodyObject) {
        return axios.post("api/admin/Lab/AddResult", bodyObject);
    },

   




    // ********************************************************************| Reports |***********************************************************


    GetMainReport(CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/Get?CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },

    GetMainReportOne(CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/GetOne?CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },










    // ********************************************************************| Users |***********************************************************
    GetUsers(pageNo, pageSize, BranchesId, MunicipalitiesId, FacilitiesId, ByDate) {
        return axios.get(`api/admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &BranchesId=${BranchesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&ByDate=${ByDate}`);
    },

    GetAllUsers() {
        return axios.get(`api/admin/User/GetAll`);
    },

    AddUser(bodyObject) {
        return axios.post("api/admin/User/Add", bodyObject);
    },

    EditUser(bodyObject) {
        return axios.post("api/admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`api/admin/User/${Id}/ChangeStatus`);
    },

    RestePassword(Id) {
        return axios.post(`api/admin/User/${Id}/RestePassword`);
    },

    DeleteUser(Id) {
        return axios.post(`api/admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },











    // ********************************************************************| Dashboard Info |***********************************************************
    GetDashboardInfo() {
        return axios.get(`/api/admin/Reports/GetDashboardInfo`);
    },


























};
