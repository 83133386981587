<script>
    //import blockUI from './BlockUIService.js';
    import DataService from './DataService.js';
    import CryptoJS from 'crypto-js';
    export default {
        data() {
            return {
                loginDetails: null,



                Diseases: [],
                AnalysisExaminations: [],
                Animals: [],
                ChronicDiseases: [],
                Complications: [],
                Countries: [],
                Branches: [],
                Municipalities: [],
                LMunicipalities: [],
                Locality: [],
                Facilities: [],
                InitialDiagnosis: [],
                Laboratories: [],
                LesionSites: [],
                Nationalities: [],
                Occupations: [],
                PatientsStatus: [],
                Procedures: [],
                RapidTests: [],
                Referrals: [],
                Relationship: [],
                Results: [],
                Samples: [],
                SiteOfBleedings: [],
                Tests: [],
                Treatments: [],
                AnimalContacts: [],
                Symptoms: [],






                /*Publeish*/
                //ServerUrl:'https://kidney.moh.com.ly',
                
                /*Local*/
                ServerUrl: 'http://localhost:5000',
                Facebock: 'https://www.facebook.com/p/Traneem-100064940462078/?locale=ar_AR',
                Instagram: 'https://www.instagram.com/traneem5__/',
                TraneemPhone: '+218 94 457 81 48',
                TraneemEmail: 'info@traneem.ly',
                PlatFormPass: 'Traneem!@Platformv1',


                WebSiteName: 'إدارة الرصد',
                BuildFor: 'إدارة الرصد الإستجابة السريعة ',
                logoPath: '/assets/img/1/3.png',



            }
        },
        methods: {

            encrypt: function encrypt(data, SECRET_KEY) {
                var dataSet = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
                dataSet = dataSet.toString();
                return dataSet;
            },
            decrypt: function decrypt(data, SECRET_KEY) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = JSON.parse(data.toString(CryptoJS.enc.Utf8));
                return data;
            },

            async CheckLoginStatus() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    if (this.loginDetails != null) {
                        const res = await DataService.IsLoggedin();
                        if (!res.data)
                            this.logout();
                    }
                } catch (error) {
                    //window.location.href = '/Login';
                }
            },

            async CheckLoginStatusRequierd() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    if (this.loginDetails != null) {
                        /*this.logout();*/
                    } else {
                        this.logout();
                    }
                } catch (error) {
                    this.logout();
                }
            },

            async logout() {
                localStorage.removeItem('currentUser-client');
                localStorage.clear();
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                this.$http.Logout()
                    .then(() => {
                        window.location.href = "/Login";
                    })
            },



            async GetAllDiseases(Type) {
                this.Diseases = [];
                try {
                    const res = await DataService.GetDiseasesByType(Type);
                    this.Diseases = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllAnalysisExaminations(id) {
                this.AnalysisExaminations = [];
                try {
                    const res = await DataService.GetAnalysisExaminationsName(id);
                    this.AnalysisExaminations = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllAnimals(id) {
                this.Animals = [];
                try {
                    const res = await DataService.GetAnimalsName(id);
                    this.Animals = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllChronicDiseases(id) {
                this.ChronicDiseases = [];
                try {
                    const res = await DataService.GetChronicDiseasesName(id);
                    this.ChronicDiseases = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllComplications(id) {
                this.Complications = [];
                try {
                    const res = await DataService.GetComplicationsName(id);
                    this.Complications = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllCountries() {
                this.Countries = [];
                try {
                    const res = await DataService.GetAllCountries();
                    this.Countries = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllBranches() {
                this.Branches = [];
                try {
                    const res = await DataService.GetAllBranches();
                    this.Branches = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllMunicipalities(id) {
                this.Municipalities = [];
                try {
                    const res = await DataService.GetAllMunicipalities(id);
                    this.Municipalities = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async LGetAllMunicipalities(id) {
                this.LMunicipalities = [];
                try {
                    const res = await DataService.GetAllMunicipalities(id);
                    this.LMunicipalities = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllLocality(id) {
                this.Locality = [];
                try {
                    const res = await DataService.GetLocalityName(id);
                    this.Locality = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllFacilities(id) {
                this.Facilities = [];
                try {
                    const res = await DataService.GetFacilitiesName(id);
                    this.Facilities = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllInitialDiagnosis(id) {
                this.InitialDiagnosis = [];
                try {
                    const res = await DataService.GetInitialDiagnosisName(id);
                    this.InitialDiagnosis = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllLaboratories(id) {
                this.Laboratories = [];
                try {
                    const res = await DataService.GetLaboratoriesName(id);
                    this.Laboratories = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllLaboratoriesInfo() {
                this.Laboratories = [];
                try {
                    const res = await DataService.GetAllLaboratoriesName();
                    this.Laboratories = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllLesionSites(id) {
                this.LesionSites = [];
                try {
                    const res = await DataService.GetLesionSitesName(id);
                    this.LesionSites = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllNationalities() {
                this.Nationalities = [];
                try {
                    const res = await DataService.GetAllNationalities();
                    this.Nationalities = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllOccupations(id) {
                this.Occupations = [];
                try {
                    const res = await DataService.GetOccupationsName(id);
                    this.Occupations = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllPatientsStatus(id) {
                this.PatientsStatus = [];
                try {
                    const res = await DataService.GetPatientsStatusName(id);
                    this.PatientsStatus = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllProcedures(id) {
                this.Procedures = [];
                try {
                    const res = await DataService.GetProceduresName(id);
                    this.Procedures = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllRapidTests(id) {
                this.RapidTests = [];
                try {
                    const res = await DataService.GetRapidTestsName(id);
                    this.RapidTests = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllReferrals(id) {
                this.Referrals = [];
                try {
                    const res = await DataService.GetReferralsName(id);
                    this.Referrals = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllRelationship(id) {
                this.Relationship = [];
                try {
                    const res = await DataService.GetRelationshipName(id);
                    this.Relationship = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllResults(id) {
                this.Results = [];
                try {
                    const res = await DataService.GetResultsName(id);
                    this.Results = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllSamples(id) {
                this.Samples = [];
                try {
                    const res = await DataService.GetSamplesName(id);
                    this.Samples = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllSiteOfBleedings(id) {
                this.SiteOfBleedings = [];
                try {
                    const res = await DataService.GetSiteOfBleedingsName(id);
                    this.SiteOfBleedings = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllTests(id) {
                this.Tests = [];
                try {
                    const res = await DataService.GetTestsName(id);
                    this.Tests = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },
            async GetAllTreatments(id) {
                this.Treatments = [];
                try {
                    const res = await DataService.GetTreatmentsName(id);
                    this.Treatments = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },
            async GetAllAnimalContacts(id) {
                this.AnimalContacts = [];
                try {
                    const res = await DataService.GetAnimalContactsName(id);
                    this.AnimalContacts = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }


            }, async GetAllSymptoms(id) {
                this.Symptoms = [];
                try {
                    const res = await DataService.GetSymptomsName(id);
                    this.Symptoms = res.data.info;
                } catch (err) {
                    //blockUI.Stop();
                }
            },













        }
    }
</script>
